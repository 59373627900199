.login-page {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: grid;
    place-items: center;
    overflow: hidden;
    background-image: url("../../assets/png/background.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .video-container {
        width: 100%;
        height: 100%;
        position: absolute;

        & video {
            opacity: .4;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .card {
        min-width: 50vw;
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0 -5px 25px rgba(0, 0, 0, .1);
        z-index: 15;
        background-color: white !important;

        .login-info {
            padding: 20px 30px;

            .heading {
                font-family: "Lato-Light";
                font-weight: 600;
                color: #8e8d8d;
                font-size: 16px;
                letter-spacing: 2px;
            }

            .store-id-subtext {
                font-family: "Lato-Light";
                font-size: 11px;
                color: #8e8d8d;
                letter-spacing: 1px;
                margin-top: 15px;
                margin-bottom: 7px;
            }

            & > input {
                width: 100%;
                border: 1px solid #d9e6f3;
                padding: 8px 15px;
                border-radius: 20px;
                background-color: rgba(63, 128, 197, 0.1);
                font-family: "Lato-Light";
                font-weight: bold;
                letter-spacing: 1px;

                &:focus {
                    outline: none;
                }
            }
        }


        .login-button {
            position: relative;
            background-color: #8e8d8d;
            height: 100px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "Lato-Light";
            font-weight: bolder;
            color: white;
            letter-spacing: 1px;
            font-size: 14px;
            overflow: hidden;
            border: 1px solid transparent;
            cursor: pointer;

            &:hover {
                border: 1px solid #8e8d8d;
            }

            &:hover span {
                color: #8e8d8d;
            }

            & span {
                z-index: 1;
            }

            &::before {
                content: '';
                position: absolute;
                top: var(--y);
                left: var(--x);
                transform: translate(-50%, -50%);
                width: 0px;
                height: 0px;
                border-radius: 50%;
                background: #fff;
                transition: width 0.5s, height 0.5s;
            }

            &:hover::before {
                width: 1600px;
                height: 1600px;
            }
        }
    }
    .error {
        color: red;
    }
}
