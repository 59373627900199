// GLOBALS
@import './global/reset';
@import './global/app';
@import './global/theme';
@import './global/animation';

// PAGES
@import './pages/home.scss';
@import './pages/questionnaire';
@import './pages/recommendation';
@import './pages/login';

// COMPONENTS
@import './components/navbar';
@import './components/spinner';
@import './components//modal';
@import './components/inactivity-modal';
@import './pages/see-answers-modal';
@import './components/preloader';
@import './components/language-selection';

#app_version {
    position: absolute;
    bottom: 10px;
    color: white;
    left: 22px;
    font-size: 10px;
}
