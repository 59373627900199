$navy-blue: #192C50; // GENERAL
$blue-secondary: #557B92; // GENERAL
$noir: #222222; // RAYBAN & OAKLEY
$rayban-noir: #1F1F24; // RAYBAN & OAKLEY
$rouge-rayban: #FF0D00;
$dark-grey: #767676;
$rayban-gris: #666666;

// *GENERAL ASSORTMENT SYLES START*

// styles - NAVBAR
.title-general {
    color: #001D27
}

// -styles - HOME PAGE
.btn-general {
    background-color: #F6F6F6;
    border-radius: 50px;
    border: 2px solid transparent;
    color: $navy-blue;
    width: 100%;
    height: 6vh;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.home-btn-general {
    @extend .btn-general;
    width: 700px;
}

// -texts
.sub-general {
    font-family: 'sofia-pro', sans-serif;
    font-weight: 800;
    color: #88ABBA;
}
.heading-general {
    margin-bottom: 15px;
    text-transform: uppercase;
    font-family: "minerva-modern", "serif";
    font-weight: lighter;
    color: $navy-blue
}

// -styles - QUESTIONNAIRE PAGE
.qst-title-general {
    text-transform: uppercase;
    font-size: 28px;
    font-family: "minerva-modern", 'serif';
    font-style: normal;
    font-weight: bolder;
    line-height: 120%;
    color: $navy-blue;
}

.duration-qst-general {
    font-family: "sofia-pro";
    color: $dark-grey;
}

.body-text-general {
    color: $dark-grey;
    font-family: "sofia-pro", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
}

.card-text-general {
    @extend .body-text-general;
    text-shadow: 0px 0px 5px black; 
}

.next-qst-btn-general {
    @extend .btn-general;
    width: 100px;
    background-color: $blue-secondary;
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 2px solid transparent;
    margin-top: 2vh;
}

.opinion-btn-general {
    @extend .btn-general;
    font-family: 'sofia-pro', sans-serif;
    font-size: 1em;
    height: 50px !important;
}

.opinion-selected-general {
    background-color: rgba(136, 171, 186, 0.3019607843);
    border-color: $blue-secondary;
    border: 2px solid $blue-secondary;
}

.side-general {
    border: 1px solid #e7eef0;
    border-radius: 15px;
    overflow: hidden;
    font-family: "sofia-pro", sans-serif;
    color: grey;
}

.selected-general {
    background-color: $navy-blue;
    color: white !important;
}

.progress-container-general {
    border-radius: 15px;
    border: 1px solid #CEB88D;
    font-family: "Avenir Next", sans-serif;
    font-weight: bolder;
    color: #CEB88D;
    background-color: rgba(206, 184, 141, 0.2);
}

.bar-general {
    background-color: rgba(206, 184, 141, 0.3);
}
.fill-general {
    background-color: #CEB88D;
}

.bottom-btn-general {
    border-radius: 50%
}


// RECOMMENDATION-PAGE
.recom-title-general {
    @extend .qst-title-general;
    color: white;
    font-size: 1.1rem;
    margin-bottom: 5px;
}

.recom-paragraph-general {
    font-family: 'sofia-pro', serif;
    font-size: 14px;
    line-height: 160%;
}

.bullet-point-general {
    @extend .recom-paragraph-general;
    color: $navy-blue;
}

.main-recom-title-general {
    @extend .qst-title-general;
    font-size: 1.5rem;
}

.pair-title-general {
    @extend .sub-general;
    font-weight: 600;
}

.product-pair-name-general {
    @extend .qst-title-general;
    font-size: 1rem;
}

.badge-general {
    font-family: 'sofia-pro';
    font-weight: bold;
}

.span-item-general {
    font-family: 'sofia-pro';  
    border-radius: 20px; 
    padding: 5px 10px !important;
    font-size: 15px;
}

.perfect-match-general {
    box-shadow: 0px 0px 25px #557B92;
    animation-name: pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.disclaimer-text-general {
    @extend .main-recom-title-general;
    margin: 20px 0px;
    font-size: 12px;
}

// *GENERAL ASSORTMENT SYLES END*

//-------------------------------------------------------------------------------------------------

// *RAYBAN STYLES START*

// styles - NAVBAR
.title-rayban {
    color: #001D27;
}

// -styles - HOME PAGE
.btn-rayban {
    background: #F6F6F6;
    border: 1px solid transparent;
    color: #000;
    width: 100%;
    height: 6vh;
    display: flex;
    justify-content: center;
}

.home-btn-rayban {
    @extend .btn-rayban;
    width: 700px;
}

// -texts
.sub-rayban {
    font-family: 'Lato-Light', sans-serif;
    color: $rayban-gris;
}
.heading-rayban {
    margin-bottom: 15px;
    text-transform: uppercase;
    font-family: 'Oswald', serif;
    // font-weight: lighter;
    // color: $navy-blue
}


// -styles - QUESTIONNAIRE PAGE
.qst-title-rayban {
    text-transform: uppercase;
    font-size: 1.3rem;
    font-family: "Oswald", serif;
    font-style: normal;
    line-height: 120%;
    font-weight: 400;
    color: $rayban-noir;
}

.duration-qst-rayban {
    font-family: "Lato-Regular", serif;
    color: $rayban-gris;
}

.body-text-rayban {
    font-family: "Lato-Regular", serif;
    color: $rayban-gris;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
}

.card-text-rayban {
    @extend .body-text-rayban;
    font-family: 'Oswald', sans-serif;
    text-shadow: 0px 0px 5px black;
}

.next-qst-btn-rayban {
    @extend .btn-rayban;
    background-color: $rouge-rayban;
    color: white;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 25px;
}

.opinion-btn-rayban {
    @extend .btn-rayban;
    font-size: 15px;
    height: 50px !important;
}

.opinion-selected-rayban {
    background: #1F1F24;
    cursor: pointer;
    color: #fff;
}

.side-rayban {
    border: 1px solid #e7eef0;
    overflow: hidden;
    font-family: "sofia-pro", sans-serif;
    color: $noir;
}

.selected-rayban {
    background-color: $rouge-rayban;
    color: white !important;
}

.progress-container-rayban {
    border: 1px solid #666666;
    font-family: "Avenir Next", sans-serif;
    font-weight: bolder;
    color: $noir;
    background-color: rgba(102, 102, 102, 0.1);
}

.bar-rayban {
    background-color: rgba(102, 102, 102, 0.3);;
}
.fill-rayban {
    background-color: grey;
}

.bottom-btn-general {
    border-radius: 10px;
}

// RECOMMENDATION-PAGE
.recom-title-rayban {
    @extend .qst-title-rayban;
    color: white;
    font-size: 1.1rem;
    margin-bottom: 5px;
}

.main-recom-title-rayban {
    @extend .qst-title-rayban;
    font-size: 1.5rem;
}

.recom-paragraph-rayban {
    font-family: 'Lato-Light';
    font-size: 15px;
    line-height: 160%;
}

.bullet-point-rayban {
    @extend .recom-paragraph-rayban;
    color: $rayban-noir;
    font-weight: bold;
}

.pair-title-rayban {
    @extend .sub-rayban;
    font-weight: 600;
    color: $rayban-noir;
}

.product-pair-name-rayban {
    @extend .qst-title-rayban;
    font-size: 1rem;
}

.badge-rayban {
    font-family: 'sofia-pro';
    font-weight: bold;
}


.span-item-rayban {
    font-family: 'Lato-Light';
    font-weight: 600;
    padding: 5px 10px !important;
    font-size: 15px;
}

.perfect-match-rayban {
    box-shadow: 0px 0px 25px $rouge-rayban;
    animation-name: pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.disclaimer-text-rayban {
    @extend .main-recom-title-rayban;
    margin: 20px 0px;
    font-size: 12px;
}

// *RAYBAN STYLES END*

//-------------------------------------------------------------------------------------------------

// *OAKLEY STYLES START*

// -styles - NAVBAR
.title-oakley {
    color: $noir;
}

// -styles - HOME PAGE
.btn-oakley {
    background: #F6F6F6;
    border: 1px solid transparent;
    border-radius: 50px;
    color: #000;
    width: 100%;
    height: 6vh;;
    display: flex;
    justify-content: center;
}

.home-btn-oakley {
    @extend .btn-oakley;
    width: 700px;
}

// -texts
.sub-oakley {
    font-family: 'Avenir-Next-Regular', sans-serif; 
    color: $noir;
    font-weight: lighter;
}
.heading-oakley {
    margin-bottom: 15px;
    text-transform: uppercase;
    font-family: "Avenir-Next-Heavy", serif;
    font-weight: bold;
    color: $rayban-noir;
}

// -styles - QUESTIONNAIRE PAGE
.qst-title-oakley {
    text-transform: uppercase;
    font-size: 1.3rem;
    font-family: "Avenir-Next-Heavy", serif;
    font-style: normal;
    line-height: 120%;
    font-weight: bold;
    color: $rayban-noir;
    letter-spacing: 1px;
}

.duration-qst-oakley {
    font-family: "Avenir-Next-Regular", serif;
    color: $noir;
    font-size: 15px;
}

.body-text-oakley {
    font-family: "Avenir-Next-Regular", serif;
    color: $noir;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 1px;
}

.card-text-oakley {
    @extend .body-text-oakley;
    font-family: 'Avenir-Next-Heavy';
    text-shadow: 0px 0px 5px black;
}

.next-qst-btn-oakley {
    @extend .btn-oakley;
    width: 100px;
    background-color: $noir;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 15px;
}

.opinion-btn-oakley {
    @extend .btn-oakley;
    font-size: 15px;
    letter-spacing: 1px;
    height: 50px !important;
    cursor: pointer;
}

.opinion-selected-oakley {
    background: #1F1F24;
    color: #fff;
}

.side-oakley {
    border: 1px solid #e7eef0;
    border-radius: 10px;
    overflow: hidden;
    font-family: "sofia-pro", sans-serif;
    color: $noir;
}

.selected-oakley {
    background-color: $noir;
    color: white !important;
}

.progress-container-oakley {
    border: 1px solid #D8D8D8;
    border-radius: 10px;
    font-family: "Avenir Next", sans-serif;
    font-weight: bolder;
    color: $noir;
    background-color: rgba(216, 216, 216, 0.1);
}

.bar-oakley {
    background-color: rgba(102, 102, 102, 0.3);;
}
.fill-oakley {
    background-color: grey;
}

.bottom-btn-oakley {
    border-radius: 10px;
}

// RECOMMENDATION - PAGE
.recom-title-oakley {
    @extend .qst-title-oakley;
    color: white;
    font-size: 1.1rem;
    margin-bottom: 5px;
}

.recom-paragraph-oakley {
    font-family: 'sofia-pro', serif;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: 1px;
}


.bullet-point-oakley {
    @extend .recom-paragraph-oakley;
}

.main-recom-title-oakley {
    @extend .qst-title-oakley;
    font-size: 1.5rem;
}

.pair-title-oakley {
    @extend .sub-oakley;
    font-weight: 600;
    letter-spacing: 1px;
    color: $noir
}

.product-pair-name-oakley {
    @extend .qst-title-oakley;
    font-size: 1rem;
}

.badge-oakley {
    font-family: 'sofia-pro';
    font-weight: bold;
    letter-spacing: 1px;
}

.span-item-oakley {
    font-family: 'sofia-pro';
    border-radius: 20px;
    letter-spacing: 1px;
    padding: 5px 10px !important;
    font-size: 15px;
}

.perfect-match-oakley {
    box-shadow: 0px 0px 25px $noir;
    animation-name: pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.disclaimer-text-oakley {
    @extend .main-recom-title-oakley;
    margin: 20px 0px;
    font-size: 12px;
}

// *OAKLEY STYLES END*

