.home-page {
    width: 100%;
    height: 100%;
    background-image: url("../../assets/png/background.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    .modal-container {
        max-width: 55rem;
        background: white;
        padding: 3rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-radius: 15px;
        margin-top: 30px;
        overflow: hidden;

        animation-name: fade-in;
        animation-duration: .2s;
        animation-timing-function: ease;

        .default {
            color: #192c50;
            text-transform: uppercase;
            font-weight: 400;
            margin-bottom: 10px;
            font-family: "minerva-modern", serif;
        }

        .brand-selection {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;

            .subtext {
                font-size: 12px;
                line-height: 120%;
                font-style: normal;
                font-weight: 800;
                line-height: 120%;
            }

            .selection-list {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: center;
            }

            .brand-images {
                flex: 1;
                margin: 0px 5px;
                transition: 0.2s;

                &:hover {
                    cursor: pointer;
                    filter: brightness(50%);
                }
            }

            .choices-container {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .btn {
                    padding: 12px 15px 11px 15px;
                    font-size: 1em;
                    transition: 200ms;
                    font-family: "sofia-pro", sans-serif;
                    height: 50px;
                }
            }
        }
    }
}

.page-number {
    color: #557B92;
    font-size: 14px;
    font-family: "sofia-pro", sans-serif;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    display: flex;
}

.paragraph {
    color: #767676;
    font-size: 15px;
    font-family: "sofia-pro", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    margin-top: 15px;
}

.modal-container .carrousel {
    padding: 0 !important;
    scroll-behavior: smooth;
    flex-direction: row !important;
    overflow: hidden;
}
.modal-container .carrousel a {
    text-decoration: none;
}
.modal-container .carrousel .slide {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
}
.modal-container .modal,
.modal-container .carrousel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
}
.modal-container .modal h2,
.modal-container .carrousel h2 {
    margin-bottom: 1rem;
    color: var(--lens-crafters-primary-blue-navy, #192c50);
    font-family: "minerva-modern", serif;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    font-size: 25px;
}

.page-number-container {
    display: flex;
    align-items: center;
}

.page-number-container .btn {
    width: 1rem;
    height: 1rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    cursor: pointer;
    margin-right: 20px;
    border-radius: 50%;

    &:hover {
        border: 1px solid #88abba;
        
    }
}

.btns-container {
    gap: 1rem;
    display: flex;
    justify-content: flex-end;
}

.align-right {
    align-self: flex-end;
}

.blue-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-end;
    border-radius: 50px;
    padding: 12px 15px 11px 15px;
    font-size: 1em;
    border: 1px solid transparent;
    background-color: #557b92;
    transition: 200ms;
    font-family: "Sofia Pro", sans-serif;
    text-transform: uppercase;
    color: white;
    font-weight: 600;

    &:hover {
        cursor: pointer;
        background-color: #f6f6f6;
        border-style: solid;
        border-color: #88abba;
        color: #88abba;
    }
}
