.see-answer-modal {
    width: 70vw;
    height: 70vh;
    background: white;

    .answer-heading {
        width: 100%;
        height: 15%;
        border-bottom: 1px solid rgba(90,102,117,.2);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px;
        position: relative;

        .benefit-info {
            display: flex;
            align-items: center;
            column-gap: 20px;

            .lens-type {
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                padding: 5px 10px;

                & img {
                    width: 25px;
                }

                & p {
                    color: white !important;
                    font-size: 14px !important;
                }
            }
        }

        .close {
            outline: none;
            border: none;
            display: grid;
            place-items: center;
            cursor: pointer;
            padding: 5px;
            position: absolute;
            top: 20px;
            right: 25px;

            & img {
                width: 20px;
            }
        }
    }

    .answers-content-container {
        width: 100%;
        height: 85%;
        overflow-y: scroll;
        padding: 10px 30px;

        .summary-item {
            border-bottom: 1px solid rgba(90,102,117,.2);
            width: 100%;
            min-height: 90px;
            height: auto;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            justify-content: space-around;
            padding: 20px 0px;

            .answers-container {
                display: flex;
                gap: 5px;
                flex-wrap: wrap;

                & p {
                    padding: 3px 8px;
                    font-size: 12px !important;
                }
            }
        }
    }
}

.close-general {
    background: #557B92;
    border-radius: 50%;
}

.close-rayban {
    background: #FF0D00;
}

.close-oakley {
    background: #222222;
    border-radius: 50%;
}

.tag-general {
    background-color: #557B92;
    color: white;
    font-family: "sofia-pro", serif;
    border-radius: 20px;
}

.tag-rayban {
    background: #FF0D00;
    color: white;
    font-family: "Lato-Light", serif; 
}

.tag-oakley {
    background: #222222;
    color: white;
    font-family: "Avenir-Next-Regular", serif;
    font-weight: bold;
    letter-spacing: 1px;
    border-radius: 20px;
}





