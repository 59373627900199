@keyframes slide-from-left {
    from {
        transform: translateX(-100%);
        opacity: 0;
        pointer-events: none;
    }

    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slide-from-right {
    from {
        transform: translateX(100%);
        opacity: 0;
        pointer-events: none;
    }

    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


@keyframes pulse {
	0% {
		transform: scale(1);
		// box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1.02);
		// box-shadow: 0 0 15px 13px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(1);
		// box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

@keyframes ripple {
	0%   { 
        transform: translate(-100%, -100%); 
    }
	80%  { 
        transform: translate(-100%, -100%) scale(50); 
    }
	100% { 
        transform: translate(-100%, -100%) scale(50); 
        opacity: 0; 
    }
}

@keyframes loader {
    from {
      transform: translate(-150px, 0px);  
    }
    to {
      transform: translate(0px, 0px); 
    }
  }
  