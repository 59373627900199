.questionnaire-page {
    margin-top: 20px;
    width: 100%;
    height: 85%;
    display: flex;

    animation-name: fade-in;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;

    .flip {
            transform: scaleX(-1);
        }

    .questionnaire-side {
        height: 85vh;
        background: white;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0.3em 2em;
        z-index: 10;

        .side-items-container {
            list-style-type: none;
            width: 18vw;

            .side-item {
                width: 100%;
                padding: 17px 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #e7eef0;
                font-family: Arial, Helvetica, serif;
                color: #192C50,;
                transition: .2s ease-in;

                &:nth-last-child(1) {
                    border-bottom: 1px solid transparent;
                }
            }
        }

        .side-progress {
            margin-top: 20px;
            width: 18vw;
            height: 12vh;
            padding: 15px 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            gap: 1vh;
            font-size: 14px;

            .percentage-container {
                display: flex;
                justify-content: space-between;
                font-family: Arial, Helvetica, sans-serif;

            }

            .percentage-bar {
                width: 100%;
                height: 0.7vh;

                .percentage-fill {
                    height: 100%;
                    transition: .3s ease-in;
                }
            }
        }
    }

    .questionnaire-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1vw;
        position: relative;
        justify-content: space-between;
        // padding: 0px 10px;

        .top-part {
            width: 95%;
            z-index: 5;

            .duration-list-container {
                display: flex;
                flex-direction: column;
                gap: 5px;
            }

            .duration-choice {
                background: white;
                padding: 8px;
                cursor: pointer;
                text-align: center;
                flex: 1;
                font-family: "sofia-pro";

                &:hover {
                    background: rgba(0, 0, 0, .2);
                    backdrop-filter: blur(25px);
                    color: white;
                }
            }

            .selected {
                background: rgba(0, 0, 0, .2);
                backdrop-filter: blur(25px);
                color: white;
            }

            .disable {
                filter: brightness(50%);
                pointer-events: none;
                cursor: not-allowed;
            }
        }

        .bottom-part {
            width: 100%;
            display: flex;
            padding-bottom: 10px;
        }

        .duration-question {
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
            margin-bottom: 20px;
            margin-top: 10px;
        }

        .duration-choices-container {
            list-style: none;
            display: flex;
            gap: 15px;

            .duration-choices {
                position: relative;
                transition: all .3s ease;
                max-width: auto;
                width: 35%;
                height: 335px;

                .selection-container {
                    position: absolute;
                    bottom: 5%;
                    left: 50%;
                    transform: translateX(-50%);
                }

                .image-type-title {
                    position: absolute;
                    bottom: 75px;
                    left: 10px;
                }

                & img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 15px;
                }
            }
        }

        .opinion-type-question {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .banner-image {
                margin-top: 10px;
                width: 70vw;
                height: 47vh;
                object-fit: cover;
                border-radius: 15px;
            }

            .opinion-choices-container {
                display: flex;
                gap: 5px;
                flex-direction: row;
                margin-top: 15px;
                width: 70vw;

                & li {
                    width: 25%;
                }
            }
        }
    }

    .bottom-navigation-container {
        display: flex;
        align-items: flex-start;
        width: 100%;
        gap: 1rem;

        .bottom-nav-button-container {
            display: flex;
            gap: 5px;

            .bottom-btn {
                width: 1rem;
                height: 1rem;
                padding: 0.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid transparent;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .huge-progress-bar-container {
            margin-top: 7px;
            // width: 67vw;
            width: 90%;

            .huge-progress-bar {
                display: flex;
                gap: 1rem;

                .progress-bar {
                    width: 100%;
                    overflow: hidden;
                    position: relative;
                    height: 4px;
                    border-radius: 3px;
                    background-color: rgba(136, 171, 186, 0.3);

                    .progress {
                        background-color: #88abba;
                        height: 100%;
                        transition: .3s ease-in;
                    }
                }
            }
        }
    }

    .slide-from-left {
        animation-name: slide-from-left;
        animation-timing-function: ease;
        animation-duration: .7s;
        animation-iteration-count: 1;
    }
    .slide-from-right {
        animation-name: slide-from-right;
        animation-timing-function: ease;
        animation-duration: .7s;
        animation-iteration-count: 1;
    }

    .duration-list-container,
    .banner-with-choices-container {
        margin-top: .3rem;
        width: 70vw; // JUMP
        object-fit: cover;
    }
    .duration-list-container > li,
    .banner-with-choices-container > li {
        position: relative;
    }

    .choice-banner {
        margin-top: 10px;
        width: 70vw;
        height: 55vh;
        object-fit: cover;
        border-radius: 15px;
    }

    .duration-container {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        bottom: 0;
        left: 0;
        padding: 0.75rem 0.5rem;
        gap: 1rem;
    }
    .duration-container > span {
        text-align: left;
        margin-right: auto;
        color: #ffffff;
    }
    .duration-container .duration-list {
        margin: auto;
        border: 1px solid #ffffff;
        border-radius: 7px;
        display: flex;
        width: 100%;
        overflow: hidden;
    }
    .duration-container .duration-list > li {
        width: 100%;
        display: flex;
    }
    .duration-container .duration-list label {
        cursor: pointer;
        white-space: nowrap;
        font-size: 1vw;
        font-family: 'sofia-pro', sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        padding: 0.5rem .2rem;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.3);
        text-align: center;
        color: #192C50;
    }
    .duration-container .duration-list input[type="radio"] {
        display: none;
    }
    .duration-container .duration-list input[type="radio"]:checked + label {
        color: #ffffff;
    }
    .duration-container
        .duration-list
        input[type="radio"]:not(:checked)
        + label {
        background-color: #ffffff;
        transition: .3s ease;   

        &:hover {
            color: white;
            backdrop-filter: blur(10px);
            background-color: rgba(255, 255, 255, 0.3);
        }
    }
}

.check {
    
    & span {
        font-size: 50px;
        color: rgba(255, 255, 255, 0.3);
    };
}


.summary-container {
    width: 100%;
    height: 100%;
    // background-image: url("../../assets/png/background.webp");
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    animation-name: fade-in;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    
    .summary-card {
        width: 775px;
        height: 475px;
        display: flex;
        overflow: hidden;
        box-shadow: 0px 10px 25px rgba(0, 0, 0, .2);
        position: relative;

        .img-container {
            width: 100%;
            height: 100%;

            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .close-icon {
            position: absolute;
            right: 10px;
            top: 5px;
            z-index: 10;
            width: 35px;
            height: 35px;
        }

        .option-container {
            flex: 1;
            background: rgba(133, 133, 133, 0.5);
            width: 45%;
            height: 100%;
            right: 0;
            position: absolute;
            backdrop-filter: blur(5px);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            row-gap: 30px;
            padding: 45px;
            
            & h2, p {
                text-align: center;
            }

            & button {
                width: 100%;
                height: 55px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0px 13px;
                background: white;
                border: none;
                outline: none;
                cursor: pointer;
                font-weight: bold;
                color: black;
                border: 2px solid transparent;
                transition: .3s ease;

                
                &:hover {
                    border: 2px solid white;
                    background: none;
                    color: white;
                    text-shadow: 0px 0px 3px rgba(0, 0, 0, .5);
                    box-shadow:  0px 0px 5px white;
                }
            }


            .separator {
                width: 100%;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;

                & div:nth-child(1), div:nth-child(3) {
                    width: 100%;
                    height: .5px;
                    background: white;
                }
            }
        }
    }

    .fr-h2 {
        font-size: 1.4em;
    }
}

.container-general {
    border-radius: 20px;

    & h2, p {
        color: white;
        font-size: 23px;
        font-family: "minerva-modern", 'serif';
        text-transform: uppercase;
    }

    & h2 {
        text-shadow: 0px 0px 3px rgba(0, 0, 0, .4);
    }

    & button {
        font-weight: bolder;
        font-family: "minerva-modern", 'serif';
        font-size: 1rem;
        border-radius: 8px;
    }
}

.container-oakley {
    border-radius: 20px;

    & h2, p {
        color: white;
        font-size: 20px;
        font-family: "Avenir-Next-Heavy", serif;
        font-style: normal;
        line-height: 120%;
        text-transform: uppercase;
    }

    & h2 {
        text-shadow: 0px 0px 3px rgba(0, 0, 0, .4);
    }

    & button {
        font-family: "sofia-pro", serif;
        font-size: 1rem;
        border-radius: 8px;
        background: black;
        color: white;
    }
}

.container-rayban {
    border-radius: 2px;

    & h2, p {
        color: white;
        font-size: 22px;
        text-transform: uppercase;
        font-family: 'Oswald', serif;
    }

    & h2 {
        text-shadow: 0px 0px 3px rgba(0, 0, 0, .4);
    }

    & button {
        text-transform: uppercase;
        font-family: 'Oswald', serif;
        font-size: 1rem;
        border-radius: 1px;
    }
}