.App {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 16px;
}

@font-face {
    font-family: "minerva-modern";
    src: url("https://use.typekit.net/af/0de237/000000000000000077359a4c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/0de237/000000000000000077359a4c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
            format("woff"),
        url("https://use.typekit.net/af/0de237/000000000000000077359a4c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
            format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 700;
    font-stretch: normal;
}

@font-face {
    font-family: "minerva-modern";
    src: url("https://use.typekit.net/af/e499e8/000000000000000077359a49/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/e499e8/000000000000000077359a49/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
            format("woff"),
        url("https://use.typekit.net/af/e499e8/000000000000000077359a49/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
            format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 700;
    font-stretch: normal;
}

@font-face {
    font-family: "minerva-modern";
    src: url("https://use.typekit.net/af/af891e/000000000000000077359a4b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/af891e/000000000000000077359a4b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
            format("woff"),
        url("https://use.typekit.net/af/af891e/000000000000000077359a4b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
            format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 400;
    font-stretch: normal;
}

@font-face {
    font-family: "minerva-modern";
    src: url("https://use.typekit.net/af/3d9e8b/000000000000000077359a48/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/3d9e8b/000000000000000077359a48/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("woff"),
        url("https://use.typekit.net/af/3d9e8b/000000000000000077359a48/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
}

@font-face {
    font-family: "sofia-pro";
    src: url("https://use.typekit.net/af/5d97ff/00000000000000007735f999/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/5d97ff/00000000000000007735f999/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
            format("woff"),
        url("https://use.typekit.net/af/5d97ff/00000000000000007735f999/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
            format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 700;
    font-stretch: normal;
}

@font-face {
    font-family: "sofia-pro";
    src: url("https://use.typekit.net/af/66e20c/00000000000000007735f993/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/66e20c/00000000000000007735f993/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
            format("woff"),
        url("https://use.typekit.net/af/66e20c/00000000000000007735f993/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
            format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 700;
    font-stretch: normal;
}

@font-face {
    font-family: "sofia-pro";
    src: url("https://use.typekit.net/af/b718ff/00000000000000007735f98d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/b718ff/00000000000000007735f98d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("woff"),
        url("https://use.typekit.net/af/b718ff/00000000000000007735f98d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
}

@font-face {
    font-family: "sofia-pro";
    src: url("https://use.typekit.net/af/c09857/00000000000000007735f98c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/c09857/00000000000000007735f98c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
            format("woff"),
        url("https://use.typekit.net/af/c09857/00000000000000007735f98c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
            format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 400;
    font-stretch: normal;
}

@font-face {
    font-family: "Akhand-Soft";
    src: url("../../assets/fonts/Akhand-Soft.otf") format("opentype");
}

@font-face {
    font-family: "Oswald";
    src: url("../../assets/fonts/Oswald-Medium.ttf") format("truetype");
}
@font-face {
    font-family: "Lato-Light";
    src: url("../../assets/fonts/Lato-Light.ttf") format("truetype");
}
@font-face {
    font-family: "Lato-Regular";
    src: url("../../assets//fonts/Lato-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Avenir-Next-Heavy";
    src: url("../../assets/fonts/Avenir-Next-Heavy.ttf") format("truetype");
}
@font-face {
    font-family: "Avenir-Next-Regular";
    src: url("../../assets/fonts/Avenir-Next-Regular.ttf") format("truetype");
}


.btn {
    padding: 12px 15px 11px 15px;
    transition: 200ms;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    align-items: center;
}

.uppercase {
    text-transform: uppercase;
}

.title-font {
    font-family: 'minerva-modern', serif;
}

.reset-list {
    list-style: none;
}

.small {
    font-size: 0.9rem;
}

.normal {
    font-size: 1.2rem;
}

.normal-plus {
    font-size: 1.4rem;
}

.big {
    font-size: 1.8rem;
}

.bold {
    font-weight: bold;
}

/* width */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    // background: rgba(203, 203, 204, 0.1);
    background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #b9b9b9;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #8f8b8b;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}