.preloader {
    width: 100%;
    height: 100%;
    background: url('../../assets//png/background.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    color: white;
    font-family: 'sofia-pro', Arial, Helvetica, sans-serif;

    .line-loader {
        width: 150px;
        height: 20px;
        background: rgb(62, 62, 62);
        margin-top: 10px;
        border-radius: 10px;
        overflow: hidden;
        border: 1px solid rgb(62, 62, 62);
      }
      
      .indicator {
        animation-name: loader;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-delay: 2s;
        
        width: 100%;
        height: 100%;
        background: white;
        border-radius: 10px;
      }
}