.inactivity-modal {
    width: 350px;
    height: 300px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(15px);
    border-radius: 16px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    font-family: 'sofia-pro';
    color: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    row-gap: 40px;


    & .buttons-container {
        display: flex;
        gap: 20px;

        & button {
            font-family: 'sofia-pro';
            min-width: 125px;
            padding: 0px 10px;
            height: 35px;
            border: none;
            outline: none;
            border-radius: 5px;
            border: 2px solid transparent;
            text-transform: uppercase;
            cursor: pointer;
            letter-spacing: 1px;
            background-color: white;
            transition: .3s ease;

            &:hover {
                border: 2px solid white;
                background: none;
                color: white;
                text-shadow: 0px 0px 3px rgba(0, 0, 0, .5);
            }

        }
    }
}