.arrow {
    margin-bottom: 3px;
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    cursor: pointer;

    &--down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }

    &--right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
      
    &--left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
    }
    
    &--up {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    }
}

.arrow-medium { // white arrow
    margin-bottom: 2px;
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    cursor: pointer;

    &--down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }

    &--right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
      
    &--left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
    }
    
    &--up {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    }
}

.language-text-general {
    font-family: "minerva-modern", 'serif';
    font-style: normal;
    font-weight: bolder;
    line-height: 120%;
}

.language-text-rayban {
    text-transform: uppercase;
    font-family: "Oswald", serif;
    font-style: normal;
    line-height: 120%;
    font-weight: 400;
}

.language-text-oakley {
    text-transform: uppercase;
    font-family: "Avenir-Next-Heavy", serif;
    font-style: normal;
    line-height: 120%;
    font-weight: bold;
    letter-spacing: 1px;
}

.info {
    font-family: "Akhand-Soft", Arial, Helvetica, sans-serif;
    display :flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    cursor: pointer;

    & h4 {
        font-size: .8rem;
    }
}

.selection-container {
    display: flex;
    flex-direction: column;

    .container {
        align-self: end;
        margin-top: 55px;
        margin-right: 25px;
        width: 225px;
        // height: 250px;
        height: auto;
        background: rgba(255, 255, 255, 0.25);
        backdrop-filter: blur(40px);
        z-index: 100;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, .5);
        overflow: auto;
        // border-radius: 5px;
        font-family: Arial, Helvetica, sans-serif;

        & select {
            text-align: center;
            width: 70px;
        }

        .top-section, .language-selection-item {
            width: 100%;
            height: 50px;
            border-bottom: 1px solid rgb(218, 201, 201); 
            display: flex;
            align-items: center;
        }

        .top-section {
            justify-content: center;
            text-align: center;

            & h4 {
                font-size: .9rem !important;
            }
        }

        .language-selection-container {
            list-style: none;
        }

        .language-selection-item {
            justify-content: space-between;
            padding: 0px 15px;
            cursor: pointer;
            transition: all .5s ease;

            // &:nth-last-child(1) {
            //     border: none;
            //     border-bottom-left-radius: 5px;
            //     border-bottom-right-radius: 5px;
            // }

            .box {
                display: flex;
                align-items: center;
                gap: 10px;

                & p {
                    font-size: .8rem !important;
                }
            }

            & img {
                width: 25px;
            }
        }
        
        .language-selected {
            box-shadow: 0px 0px 5px rgba(0, 0, 0, .5);
            border: 1px solid white !important;
            // color: white !important;
        }

        .arrow-container {
            width: 25px;
            height: 25px;
            padding: 5px;
            padding-left: 7px;
            padding-top: 4px;
            border-radius: 50%;
        }

        .selected {
            background: linear-gradient(110deg, rgba(129,193,224,1) 0%, rgba(118,147,182,1) 29%, rgba(36,126,158,1) 57%);
        }

        .selected-general {
            background: #557B92;
        }

        .selected-rayban {
            background: #FF0D00;
        }

        .selected-oakley {
            background: #222222;
        }
    }

    .container-radius-general {
        border-radius: 10px;

        .language-selection-item {
            &:nth-last-child(1) {
                border: none;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }
    }
    .container-radius-rayban {
        border-radius: 1px;
    }
    .container-radius-oakley {
        border-radius: 10px;

        .language-selection-item {
            &:nth-last-child(1) {
                border: none;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }
    }
}