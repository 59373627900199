.recommendation-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    animation-name: fade-in;
    animation-duration: .5s;
    animation-timing-function: ease;

    .main-pair-section {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 900px;

        .banner-container {
            position: relative;
            overflow: hidden;

            .banner-image {
                width: 900px;
                height: 233px;
                object-fit: cover;
                border-radius: 20px;
            }

            .recom-info-container {
                position: absolute;
                top: 0px;
                right: 0px; 
                width: 50%;
                height: 98.3%;
                padding: 50px 20px;
                background-color: rgba(0, 0, 0, 0.4);
                backdrop-filter: blur(25px);
                border-bottom-right-radius: 14px;
                border-top-right-radius: 14px;
                color: white;
                display: flex;
                flex-direction: column;
                justify-content: center;


                .benefits-container {
                    margin-top: 20px;
                    
                    .benefit-list {
                        display: flex;
                        gap: 10px;

                        .benefit-item {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;
                            border-right: 1px solid rgba(255, 255, 255, 0.4);
                            flex-wrap: nowrap;

                            &:nth-last-child(1) {
                                border-right: 0px solid rgba(255, 255, 255, 0.4);
                            }
                        }
                    }
                }
            }
        }

        .main-pair-cards-container {
            margin-top: 25px;
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 15px;

            .main-pair-card {
                border: 1px solid rgb(228, 228, 228);
                border-radius: 15px;
                width: 300px;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 10px 15px;  
                row-gap: 15px;

                .reco-container {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    .pair-name {
                        display: flex;
                        align-items: center;
                        font-family: "sofia-pro", sans-serif;
                        font-size: 12px;
    
                        & img {
                            width: 25px;
                            margin-right: 15px;
                        }
                    }
    
                    .product-recommendation-list {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
    
                        .bullet-point {
                            display: flex;
                            align-items: center;
                            padding: 6px 8px; // JUMP
                            border-radius: 50px;
                            background: #F6F6F6;
                        }
    
                    }
                }

                .see-answer {
                    display: flex;
                    align-items: center;
                    align-self: flex-end;
                    column-gap: 3px;
                    font-size: 11px !important;
                    cursor: pointer;
                    margin-top: 5px;
                    transition: all .3s ease;
                    border-bottom: 1px solid transparent;

                    &:hover {
                        border-bottom: 1px solid rgb(215, 215, 215);
                    }
                }


            }
        }

    }

    .additional-pair-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 900px;

        .additional-pair-cards-container {
            margin-top: 25px;
            display: flex;
            justify-content: center;
            gap: 20px;
            width: 100%;

            .additional-pair-card {
                width: 50%;
                height: 425px;
                overflow: hidden;
                position: relative;
                border-radius: 15px;

                .additional-pair-card-image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 15px;
                }

                .badge {
                    position: absolute;
                    top: 15px;
                    left: 15px;
                    background: #fff;
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 3px;
                    border-radius: 20px;
                    padding: 3px 7px;

                    .dot {
                        width: 5px;
                        height: 5px;
                        background: #6E51EB;
                        border-radius: 50%;
                        margin-right: 3px;
                    }
                }

                .additional-pair-info-container {
                    position: absolute;
                    bottom: 0px;
                    width: 100%;
                    height: 40%;
                    background-color: rgba(0, 0, 0, 0.4);
                    backdrop-filter: blur(25px);
                    z-index: 1;
                    border-bottom-left-radius: 15px;
                    border-bottom-right-radius: 15px;
                    color: white;
                    padding: 15px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 10px;
                    padding: 20px;

                    .additional-pair-benefit-items-container{
                        margin-top: 10px;

                        .additional-benefit-list {
                            display: flex;
                            gap: 5px;

                            .additional-benefit-item {
                                padding: 0px 10px;
                                border-right: 1px solid rgb(209, 205, 205);

                                &:nth-last-child(1) {
                                    border-right: 0px solid transparent;
                                }

                            }
                        }
                    }
                }
            }

        }
    }

    .package-offer-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 900px;
        margin-bottom: 50px;

        .package-offer-cards-container {
            margin-top: 25px;
            display: flex;
            justify-content: center;
            gap: 15px;

            .package-offer-card {
                overflow: hidden;
                position: relative;
                border-radius: 15px;
                width: 290px;
                // height: 400px;
                // height: auto;
                transition: all .3s ease;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                .badge, .badge-alt {
                    position: absolute;
                    top: 15px;
                    left: 15px;
                    background: #fff;
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 3px;
                    border-radius: 20px;
                    padding: 3px 7px;

                    .dot {
                        width: 5px;
                        height: 5px;
                        background: #6E51EB;
                        border-radius: 50%;
                        margin-right: 3px;
                    }
                }
                .badge-alt {
                    top: 45px;
                }
                .package-offer-card-image {
                    border-radius: 15px;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                
                .package-info-container {
                    width: 100%;
                    height: 55%;
                    background-color: rgba(0, 0, 0, 0.4);
                    backdrop-filter: blur(25px);
                    border-bottom-left-radius: 15px;
                    border-bottom-right-radius: 15px;
                    color: white;
                    position: absolute;
                    bottom: 0;

                    .product-name-container {
                        padding: 5px 10px;
                        border-bottom: 1px solid #D8D8D8;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        height: 50px;

                        & h3 {
                            margin-bottom: 0px !important;
                        }
                    }

                    .lenses-container {
                        width: 100%;
                        
                        .lenses-list {
                            width: 100%;
                            padding: 15px;

                            .list-item {
                                display: flex;
                                align-items: center;
                                margin-bottom: 5px;
                                
                                & > img {
                                    margin-right: 15px;
                                }
    
                                & > span {
                                    border: 2px solid white;
                                    padding: 5px 15px;
                                    width: 90%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .disclaimer-section {
        width: 100%;
        padding: 5px;

        & p {
            text-align: left;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 10px;
            display: flex;
            flex-direction: column;
        }
    }
}

.divider {
    width: 100%;
    height: 1px;
    background: rgb(240, 240, 240);
    margin: 25px 0px;
}

