.navbar {
    background: #fff;
    padding: 0em 1.8vw;
    height: 77px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    // top: 0px; // JUMP - 
    z-index: 100;

    .brand-logo {
        // width: 5vw;
        object-fit: cover;
        cursor: pointer;
    }

    .title {     
        font-family: "Akhand-Soft", Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: bold;
        line-height: 120%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-size: 25px;
    }

    .buttons-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;
        animation-name: fade-in;
        animation-timing-function: ease;
        animation-duration: 1s;
        margin-top: 10px;

        & a {
            text-decoration: none;
        }


        & button {
            margin-top: 0px !important;
            display: flex;
            position: relative;
        }

        .back-button, .restart-button, .deep-link-btn {
            font-size: 1vw;
            width: 8.5vw;
            height: 5vh;
            padding: 5px 0px;
        }

        .back-button {
            
            & span {
                position: absolute;
                left: 2vw;
            }
        }

        .restart-button {
            display: flex;

            & span {
                margin-left: 10px;
                // position: absolute;
                // left: 1.5vw;
            }
        }
    }

 
    & svg {
        padding: 3px;
        cursor: pointer;
    }

    .back-icon-general {
        height: 3.5vh;
        fill: white;
        position: absolute;
        right: 10px;
    }

    .back-icon-rayban {
        height: 3.5vh;
        fill: white;
        position: absolute;
        right: 10px;
    }

    .back-icon-oakley {
        height: 3.5vh;
        fill: white;
        position: absolute;
        right: 10px;
    }


    .restart-icon-general {
        height: 3.3vh;
        fill: white;
        // position: absolute;
        right: 7px;
    }

    .restart-icon-rayban {
        height: 3.3vh;
        fill: white;
        // position: absolute;
        right: 7px;
    }

    .restart-icon-oakley {
        height: 3.3vh;
        fill: white;
        // position: absolute;
        right: 7px;
    }
}