.modal-content-container {
    z-index: 9999;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
}

.outside {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.overlay {
    background: rgba(0, 0, 0, 0.3);
}

.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
}
